<template>
  <v-container fluid>
    <v-row no-gutters>
      <!-- Main Card -->
      <v-col cols="12" v-if="isLoaded" class="mt-6">
        <v-card class="mx-2">
          <v-row no-gutters>
            <v-col cols="12" class="mt-2">
              <v-row justify="space-between" no-gutters>
                <div class="mx-2">
                  <h1>{{ item.name }}</h1>
                </div>
                <div class="mx-2">
                  <!-- Btn compute distances -->
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        icon
                        @click="computeDistanceSteps"
                        :loading="isLoading.btnComputeDistanceSteps"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-icon>fas fa-satellite</v-icon>
                      </v-btn>
                    </template>
                    <span>
                      {{ $t("materials.buttons.refreshDistanceSteps") }}
                    </span>
                  </v-tooltip>

                  <!-- Btn refresh grades -->
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        icon
                        @click="refreshMaterialGrades"
                        :loading="isLoading.btnRefreshGrades"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-icon>fas fa-star</v-icon>
                      </v-btn>
                    </template>
                    <span>{{ $t("materials.buttons.computeGrades") }}</span>
                  </v-tooltip>

                  <!-- Btn edit -->
                  <v-btn icon @click="setShowMainEditDialog(true)">
                    <v-icon>fas fa-pencil</v-icon>
                  </v-btn>

                  <!-- Btn delete -->
                  <v-btn icon @click="setShowDeleteDialog(true)">
                    <v-icon>far fa-trash</v-icon>
                  </v-btn>
                </div>
              </v-row>
            </v-col>

            <!-- Supplier -->
            <v-col cols="12">
              <span>{{ $t("materials.labels.supplier") }} :</span>
              <SupplierLink :supplier="item.supplier" />
            </v-col>

            <!-- Raw material -->
            <v-col cols="12">
              <span>{{ $t("materials.labels.raw_material") }} :</span>
              <span>{{ item.raw_material }}</span>
            </v-col>

            <!-- Traceability grade -->
            <v-col cols="12">
              <span>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <span class="pointer" v-bind="attrs" v-on="on">
                      {{ $t("materials.labels.traceability_grade") }} :
                    </span>
                  </template>
                  <span class="font-weight-bold">
                    {{ item.traceability_grade }} / 5
                  </span>
                </v-tooltip>
              </span>
              <v-rating
                v-model="item.traceability_grade"
                background-color="purple lighten-3"
                readonly
                color="yellow"
                half-increments
                hover
                small
              />
            </v-col>

            <!-- Compliance grade -->
            <v-col cols="12">
              <span>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <span class="pointer" v-bind="attrs" v-on="on">
                      {{ $t("materials.labels.compliance_grade") }} :
                    </span>
                  </template>
                  <span class="font-weight-bold">
                    {{ item.compliance_grade }} / 5
                  </span>
                </v-tooltip>
              </span>
              <span>
                <v-rating
                  v-model="item.compliance_grade"
                  background-color="purple lighten-3"
                  readonly
                  color="yellow"
                  half-increments
                  hover
                  small
                ></v-rating>
              </span>
            </v-col>

            <!-- Total KM -->
            <v-col cols="12" class="my-1">
              <span>{{ $t("materials.labels.totalKM") }} :</span>
              <span>{{ item.totalKM }}</span>
            </v-col>
          </v-row>
        </v-card>
      </v-col>

      <!-- Tabs card -->
      <v-col cols="12" class="mt-6" v-if="isLoaded">
        <v-card class="mx-2">
          <v-tabs class="mx-2" v-model="tab" grow @change="switchTab">
            <v-tab
              class="text-transform-none font-size-14"
              href="#tab-1"
              :exact="true"
            >
              {{ $t("materials.tabs.traceability.title") }}
            </v-tab>
            <v-tab
              class="text-transform-none font-size-14"
              href="#tab-2"
              :exact="true"
            >
              {{ $t("materials.tabs.components.title") }}
            </v-tab>
          </v-tabs>

          <v-tabs-items v-model="tab" class="ma-1">
            <!-- Traceability -->
            <v-tab-item value="tab-1">
              <MaterialTraceabilityTab
                v-if="item && isLoaded"
                :material="item"
                :traceability_steps="item.traceability_steps"
                @refresh="getData"
              />
              <Skeleton v-else type="card" :occurrence="1" />
            </v-tab-item>

            <!-- Components -->
            <v-tab-item value="tab-2">
              <MaterialComponentsTab v-if="item && isLoaded" :material="item" />
              <Skeleton v-else type="card" :occurrence="1" />
            </v-tab-item>
          </v-tabs-items>
        </v-card>
      </v-col>
    </v-row>

    <!-- Material main dialog -->
    <MaterialMainDialog
      v-if="showDialog.main"
      :value="showDialog.main"
      :item="item"
      @close="(needRefresh) => setShowMainEditDialog(false, needRefresh)"
    />

    <!-- Component delete dialog -->
    <DeleteDialog
      v-if="showDialog.delete"
      :value="showDialog.delete"
      :title="$t('materials.deleteDialog.title')"
      @submit="deleteItem"
      @close="(needRefresh) => setShowDeleteDialog(false, needRefresh)"
    />
  </v-container>
</template>

<script>
import formatter from "@/mixins/formatter";
import tabsUtils from "@/mixins/tabs-utils";
export default {
  name: "MaterialView",

  components: {
    MaterialComponentsTab: () =>
      import("@/components/Materials/MaterialComponentsTab"),
    MaterialTraceabilityTab: () =>
      import("@/components/Materials/MaterialTraceabilityTab"),
    MaterialMainDialog: () =>
      import("@/components/Materials/MaterialMainDialog"),
    SupplierLink: () => import("@/components/Common/Cards/SupplierLink"),
    Skeleton: () => import("@/components/Common/Cards/Skeleton"),
    DeleteDialog: () => import("@/components/Common/DeleteDialog"),
  },

  mixins: [formatter, tabsUtils],

  data() {
    return {
      showDialog: {
        main: false,
        delete: false,
      },

      isLoaded: false,
      isLoading: {
        btnSave: false,
        btnRefreshGrades: false,
        btnComputeDistanceSteps: false,
      },
      item: null,
      tab: null,
    };
  },

  created() {
    this.getData();
  },

  methods: {
    getData() {
      this.isLoaded = false;
      this.isLoading.btnSave = false;

      this.$http
        .get(`/materials/${this.$route.params.id}`, {
          headers: { Authorization: "Bearer " + this.$session.get("jwt") },
        })
        .then((res) => {
          this.item = res.data;
          this.$store.commit("pageHead/setTitle", `${this.item.name}`);
        })
        .catch((err) => {
          this.$store.commit("toast/showErrorAxios", err);
        })
        .finally(() => {
          this.isLoaded = true;
        });
    },

    deleteItem() {
      this.$http
        .delete(`/materials/${this.item.id}`, {
          headers: { Authorization: "Bearer " + this.$session.get("jwt") },
        })
        .then((res) => {
          this.$store.commit(
            "toast/showSuccess",
            this.$t("materials.deleteDialog.success")
          );
          this.setShowDeleteDialog(false);
          this.$router.push({ name: "Materials" });
        })
        .catch((err) => {
          this.$store.commit("alert/showErrorAxios", err);
        })
        .finally(() => {
          this.isLoadingBtnSave = false;
        });
    },

    deleteTraceabilityStep(stepId) {
      this.$http
        .delete(`/materials/${this.item.id}/composition/${stepId}`, {
          headers: { Authorization: "Bearer " + this.$session.get("jwt") },
        })
        .then((res) => {
          // this.$store.commit("toast/showSuccess", this.$t('components.deleteDialog.success'));
          this.getData();
        })
        .catch((err) => {
          this.$store.commit("alert/showErrorAxios", err);
        })
        .finally(() => {});
    },

    setShowMainEditDialog(val, reloadData = false) {
      if (reloadData) this.getData();

      this.showDialog.main = val;
    },

    setShowDeleteDialog(val, reloadData = false) {
      if (reloadData) this.getData();

      this.showDialog.delete = val;
    },

    refreshMaterialGrades() {
      this.isLoading.btnRefreshGrades = true;

      this.$http
        .patch(
          `/materials/${this.$route.params.id}/refresh-grades`,
          {},
          {
            headers: { Authorization: "Bearer " + this.$session.get("jwt") },
          }
        )
        .then((res) => {
          this.getData();
        })
        .catch((err) => {
          this.$store.commit("toast/showErrorAxios", err);
        })
        .finally(() => {
          this.isLoading.btnRefreshGrades = false;
        });
    },

    computeDistanceSteps() {
      this.isLoading.btnComputeDistanceSteps = true;

      this.$http
        .patch(
          `/materials/${this.$route.params.id}/compute-distance-steps`,
          {},
          {
            headers: { Authorization: "Bearer " + this.$session.get("jwt") },
          }
        )
        .then((res) => {
          this.getData();
        })
        .catch((err) => {
          this.$store.commit("toast/showErrorAxios", err);
        })
        .finally(() => {
          this.isLoading.btnComputeDistanceSteps = false;
        });
    },
  },
};
</script>

<style scoped></style>
