<template>
  <v-container fluid>
    <v-row justify="start" class="ma-2" no-gutters>
      <v-col cols="12">
        <h1>{{ $t("materials.title") }}</h1>
      </v-col>

      <!-- Buttons -->
      <v-col cols="12" class="mt-6 text-right">
        <v-btn color="primary" @click="toggleAddDialog(true)">
          {{ $t("materials.buttons.add") }}
        </v-btn>
      </v-col>

      <v-col cols="12" class="mt-6">
        <div>
          <v-text-field
            v-model="search"
            append-icon="fas fa-search"
            :label="$t('common.inputs.search')"
            clearable
            v-on:click:clear="clearInput"
            @keyup.enter.native="searchTrigger"
            outlined
          ></v-text-field>
        </div>
      </v-col>

      <!-- Table -->
      <v-col cols="12" class="mt-2">
        <v-data-table
          :headers="headers"
          :items="items"
          :server-items-length="totalItems"
          :options.sync="pagination"
          :loading="loading"
          :items-per-page="20"
          style="min-height: 600px"
          sort-by="name"
          :sort-desc="false"
          :footer-props="{ 'items-per-page-options': itemsPerPage }"
          class="elevation-1"
        >
          <!-- Name -->
          <template v-slot:item.name="{ item }">
            <router-link :to="{ name: 'Material', params: { id: item.id } }">
              {{ item.name }}
            </router-link>
          </template>

          <!-- Supplier -->
          <template v-slot:item.supplier="{ item }">
            <SupplierLink :supplier="item.supplier" />
          </template>

          <!-- Raw Material -->
          <template v-slot:item.raw_material="{ item }">
            {{ item.raw_material }}
          </template>

          <!-- Actions -->
          <template v-slot:item.actions="{ item }">
            <v-btn text @click="duplicateMaterial(item.id)">
              {{ $t("materials.buttons.duplicate") }}
            </v-btn>
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <!-- Main project dialog -->
    <MaterialMainDialog
      v-if="showDialog.add"
      :value="showDialog.add"
      :item="null"
      @close="(needRefresh) => toggleAddDialog(false, needRefresh)"
    />
  </v-container>
</template>

<script>
import vuetifyDatatable from "@/mixins/vuetifyDataTable";
import formatter from "@/mixins/formatter";

export default {
  name: "MaterialsView",

  components: {
    MaterialMainDialog: () =>
      import("@/components/Materials/MaterialMainDialog"),
    SupplierLink: () => import("@/components/Common/Cards/SupplierLink"),
  },

  mixins: [vuetifyDatatable, formatter],

  data() {
    return {
      headers: [
        {
          text: this.$t("materials.datatable.headers.name"),
          value: "name",
          sortable: false,
          width: "40%",
        },
        {
          text: this.$t("materials.datatable.headers.supplier"),
          value: "supplier",
          sortable: false,
          width: "30%",
        },
        {
          text: this.$t("materials.datatable.headers.raw_material"),
          value: "raw_material",
          sortable: false,
          width: "20%",
        },
        {
          text: "",
          value: "actions",
          sortable: false,
        },
      ],
      URL_API: {
        GET_ALL: "/materials",
      },

      showDialog: {
        add: false,
      },
      cloneCurrentItem: null,
      isLoadingExportBtn: false,
      savePaginationURL: true,
    };
  },

  created() {
    this.$store.commit("pageHead/setTitle", this.$t("materials.pageTitle"));
  },

  methods: {
    toggleAddDialog(val, reloadData = false) {
      if (reloadData) this.searchTrigger();
      this.showDialog.add = val;
    },

    goToOne(obj) {
      this.$router.push({
        name: "Material",
        params: { id: obj.id },
      });
    },

    duplicateMaterial(itemId) {
      this.cloneCurrentItem = itemId;

      this.$http
        .post(
          `/materials/clone`,
          { srcId: itemId },
          {
            headers: { Authorization: "Bearer " + this.$session.get("jwt") },
          }
        )
        .then((res) => {
          this.searchTrigger();
        })
        .catch((err) => {
          this.$store.commit("alert/showErrorAxios", err);
        })
        .finally(() => {
          this.cloneCurrentItem = null;
        });
    },
  },
};
</script>

<style scoped></style>
